var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"my-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',{attrs:{"id":"terms-and-conditions"}},[_c('section',{staticClass:"introduction"},[_vm._m(1),_c('article',{staticClass:"details"},[_c('ul',[_vm._m(2),_c('li',[_vm._v(" On the landing page, click on the "),_c('router-link',{attrs:{"to":"/newsales"}},[_vm._v("shop")]),_vm._v(" tab ")],1),_c('li',[_vm._v(" Select the product or solution you require from the catalogue ")]),_vm._m(3),_c('li',[_vm._v("Selected product will be added to your cart")]),_c('li',[_vm._v(" Continue shopping or click on your cart to checkout, if satisfied. ")]),_c('li',[_vm._v(" At checkout, you will be prompted to login to your imperium account ")]),_c('li',[_vm._v(" If you do not have an account, you can signup and continue with payment ")]),_c('li',[_vm._v("Choose the preferred mode of payment:")]),_vm._m(4),_c('li',[_vm._v(" If outright payment is selected, you will be redirected to the payment gateway (Flutterwave), and be required to fill in your card details, after which purchase will be confirmed successful. ")]),_c('li',[_vm._v(" If lease to own is selected (for product/solution <N2m), you will be redirected to the ‘Pay-With-Specta’ platform. ")]),_vm._m(5),_c('li',[_vm._v(" If product/solution > N2m you will be redirected to a form: ")]),_vm._m(6),_c('li',[_vm._v(" After successful payment, you will receive an email notification to this effect. ")]),_c('li',[_vm._v(" You will be contacted by Imperium’s administrative officer as well as a technical partner (vendor) to verify the details of your purchase and make preparations for the installation process. ")])])])]),_vm._m(7)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-pd bg-pr-trans"},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"container shape-container d-flex align-items-center text-center-sm"},[_c('div',{staticClass:"col px-0"},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-lg-5"},[_c('h1',{staticClass:"text-dark display-1 f-bold text-head mb-3"},[_vm._v(" How To Buy And Sell On Imperium ")]),_c('p',{staticClass:"f-16 text-dark mb-0"})]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"headerimg",attrs:{"src":"/assets/images/svgs/buyandsell.svg","alt":""}})])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h3',[_vm._v("How to buy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v(" Visit the "),_c('a',{attrs:{"href":"https:\\\\www.imperiumng.com"}},[_vm._v("website")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Alternatively, you can click on ‘Go to calculator’ for an automated assessment of your energy needs. ")]),_c('li',[_vm._v(" Input the details of the appliances you would like to power. ")]),_c('li',[_vm._v(" Select the preferred solution as recommended by the energy calculator. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Outright payment")]),_c('li',[_vm._v("Lease to own")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Your specta ID will be requested.")]),_c('li',[_vm._v("You can signup to create a Specta ID")]),_c('li',[_vm._v(" After filling the necessary details on the Pay-With-Specta platform, you will receive an invoice with details of your lease agreement. ")]),_c('li',[_vm._v(" You are required to review the terms of the lease agreement. ")]),_c('li',[_vm._v(" If the terms are suitable, your purchase will be confirmed as successful ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Provide the details required")]),_c('li',[_vm._v(" You will be directed to the Sterling Bank branch of your choice. ")]),_c('li',[_vm._v(" You will be required to submit relevant documents to assess your credit worthiness. ")]),_c('li',[_vm._v(" If the results of the credit check are satisfactory, the loan will be approved and funds disbursed, upon agreeing with the terms of the loan agreement. ")]),_c('li',[_vm._v("Purchase will be confirmed as successful.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"use-of-site"},[_c('header',[_c('h3',[_vm._v("How to sell")])]),_c('article',{staticClass:"details"},[_c('ul',[_c('li',[_vm._v(" Visit the "),_c('a',{attrs:{"href":"https:\\\\www.imperiumng.com"}},[_vm._v("website")])]),_c('li',[_vm._v(" Scroll down the landing page till you see ‘Become a vendor’, and click on signup ")]),_c('li',[_vm._v(" You will be required to fill the details of your business, level of experience, etc. and upload documentation to validate these. ")]),_c('li',[_vm._v(" The data provided will be assessed to ensure you meet our minimum requirements. ")]),_c('li',[_vm._v(" If your business meets the requirements, you will be notified. ")]),_c('li',[_vm._v(" You will receive an SLA and NDA agreement via email. ")]),_c('li',[_vm._v(" Upon agreeing to the terms stated in the SLA and NDA, you will then be onboarded on the platform. ")]),_c('li',[_vm._v(" You can proceed to upload your product/solutions catalog ")]),_c('li',[_vm._v(" When a customer places an order on an item in your catalog, you will receive a notification. ")]),_c('li',[_vm._v(" You will be contacted by the imperium administrative officer to confirm the order placement. ")]),_c('li',[_vm._v(" Contact the customer to follow-up with the installation process. ")])])])])
}]

export { render, staticRenderFns }