<template>
  <div>
    <div class="section section-pd bg-pr-trans">
      <div class="page-header">
        <div
          class="container shape-container d-flex align-items-center text-center-sm"
        >
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-5">
                <h1 class="text-dark display-1 f-bold text-head mb-3">
                  How To Buy And Sell On Imperium
                </h1>
                <p class="f-16 text-dark mb-0">
                  <!-- Welcome to Imperium website (the "Site"). -->
                </p>
              </div>
              <div class="col-md-7">
                <div class="text-center">
                  <img
                    class="headerimg"
                    src="/assets/images/svgs/buyandsell.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div id="terms-and-conditions">
              <section class="introduction">
                <header><h3>How to buy</h3></header>
                <article class="details">
                  <ul>
                    <li>
                      Visit the <a href="https:\\www.imperiumng.com">website</a>
                    </li>
                    <li>
                      On the landing page, click on the
                      <router-link to="/newsales">shop</router-link> tab
                    </li>
                    <li>
                      Select the product or solution you require from the
                      catalogue
                    </li>
                    <ul>
                      <li>
                        Alternatively, you can click on ‘Go to calculator’ for
                        an automated assessment of your energy needs.
                      </li>
                      <li>
                        Input the details of the appliances you would like to
                        power.
                      </li>
                      <li>
                        Select the preferred solution as recommended by the
                        energy calculator.
                      </li>
                    </ul>
                    <li>Selected product will be added to your cart</li>
                    <li>
                      Continue shopping or click on your cart to checkout, if
                      satisfied.
                    </li>
                    <li>
                      At checkout, you will be prompted to login to your
                      imperium account
                    </li>
                    <li>
                      If you do not have an account, you can signup and continue
                      with payment
                    </li>
                    <li>Choose the preferred mode of payment:</li>
                    <ul>
                      <li>Outright payment</li>
                      <li>Lease to own</li>
                    </ul>
                    <li>
                      If outright payment is selected, you will be redirected to
                      the payment gateway (Flutterwave), and be required to fill
                      in your card details, after which purchase will be
                      confirmed successful.
                    </li>
                    <li>
                      If lease to own is selected (for product/solution
                      &lt;N2m), you will be redirected to the ‘Pay-With-Specta’
                      platform.
                    </li>
                    <ul>
                      <li>Your specta ID will be requested.</li>
                      <li>You can signup to create a Specta ID</li>
                      <li>
                        After filling the necessary details on the
                        Pay-With-Specta platform, you will receive an invoice
                        with details of your lease agreement.
                      </li>
                      <li>
                        You are required to review the terms of the lease
                        agreement.
                      </li>
                      <li>
                        If the terms are suitable, your purchase will be
                        confirmed as successful
                      </li>
                    </ul>
                    <li>
                      If product/solution > N2m you will be redirected to a
                      form:
                    </li>
                    <ul>
                      <li>Provide the details required</li>
                      <li>
                        You will be directed to the Sterling Bank branch of your
                        choice.
                      </li>
                      <li>
                        You will be required to submit relevant documents to
                        assess your credit worthiness.
                      </li>
                      <li>
                        If the results of the credit check are satisfactory, the
                        loan will be approved and funds disbursed, upon agreeing
                        with the terms of the loan agreement.
                      </li>
                      <li>Purchase will be confirmed as successful.</li>
                    </ul>
                    <li>
                      After successful payment, you will receive an email
                      notification to this effect.
                    </li>
                    <li>
                      You will be contacted by Imperium’s administrative officer
                      as well as a technical partner (vendor) to verify the
                      details of your purchase and make preparations for the
                      installation process.
                    </li>
                  </ul>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>How to sell</h3></header>
                <article class="details">
                  <ul>
                    <li>
                      Visit the <a href="https:\\www.imperiumng.com">website</a>
                    </li>
                    <li>
                      Scroll down the landing page till you see ‘Become a
                      vendor’, and click on signup
                    </li>
                    <li>
                      You will be required to fill the details of your business,
                      level of experience, etc. and upload documentation to
                      validate these.
                    </li>
                    <li>
                      The data provided will be assessed to ensure you meet our
                      minimum requirements.
                    </li>
                    <li>
                      If your business meets the requirements, you will be
                      notified.
                    </li>
                    <li>
                      You will receive an SLA and NDA agreement via email.
                    </li>
                    <li>
                      Upon agreeing to the terms stated in the SLA and NDA, you
                      will then be onboarded on the platform.
                    </li>
                    <li>
                      You can proceed to upload your product/solutions catalog
                    </li>
                    <li>
                      When a customer places an order on an item in your
                      catalog, you will receive a notification.
                    </li>
                    <li>
                      You will be contacted by the imperium administrative
                      officer to confirm the order placement.
                    </li>
                    <li>
                      Contact the customer to follow-up with the installation
                      process.
                    </li>
                  </ul>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" >
#terms-and-conditions {
  color: black;

  ul li {
    margin-top: 14px;
  }
  ul ul li {
    margin-top: 8px;
  }
  h3 {
    color: #219653;
    opacity: 0.8;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  h4 {
    color: black;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
#terms-and-conditions .details table {
  width: 80%;
  font-size: 1.1rem;
  border-collapse: collapse;
  margin: 0 auto 2rem;
  th {
    font-weight: 600;
  }
}
#terms-and-conditions .details table td,
#terms-and-conditions .details table th {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
  padding-left: 2rem;
}
article.details p {
  line-height: 30px;
  margin-bottom: 40px;
}
.return-policies .bullet-points {
  padding-left: 30px;
}
.return-policies strong,
.mysp {
  margin-right: 10px;
  display: inline-block;
}

.headerimg {
  width: 100%;
}
</style>